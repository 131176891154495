import Vue from 'vue'

import {
  provideFluentDesignSystem,
  fluentCard,
  fluentButton
} from '@fluentui/web-components'

import App from './App.vue'
import router from './router'
import store from './store'

import { btnnegroom } from '@/components/Ribbons/ZoneBalancingTable/negativeZoneBalancing'
import { btnposroom } from '@/components/Ribbons/ZoneBalancingTable/positiveZoneBalancing'
import { btnvfx } from '@/components/Ribbons/CreateSchedules/VfxPopup'
import { btnvv } from '@/components/Ribbons/CreateSchedules/VvPopup'
import { btnvvhwc } from '@/components/Ribbons/CreateSchedules/VvhwcPopup'
import { btnvvec } from '@/components/Ribbons/CreateSchedules/VvecPopup'
import { btnweb, btnImperial, btnMetric } from '@/components/Ribbons/Commands/commands'
import { btnvvsoundcalc } from '@/components/Ribbons/Calculators/VVCalculators/SoundPerformance/vvSoundCalc'
import { btnvvsizingcalc } from '@/components/Ribbons/Calculators/VVCalculators/QuickSizing/vvSizingCalc'
import { btnvfxsoundcalc } from '@/components/Ribbons/Calculators/VfxCalculators/SoundPerformance/vfxSoundCalc'
import { btnvfxoperatingcalc } from '@/components/Ribbons/Calculators/VfxCalculators/PressureDrop/vfxPressureDropCalc'
import { btnvfxsizingcalc } from '@/components/Ribbons/Calculators/VfxCalculators/QuickSizing/vfxSizingCalc'
import { btnvvhwccalc } from '@/components/Ribbons/Calculators/VvhwcCalculators/vvhwcPerformanceCalc'
import {
  generateVvMaxDischarge,
  generateVvMaxRadiated,
  generateVvMaxInlet
} from '@/components/Ribbons/Calculators/VVCalculators/SoundPerformance/vvSoundCustomFunctions.js'
import {
  generateVfxMaxDischarge,
  generateVfxMaxRadiated,
  generateVfxMaxInlet
} from '@/components/Ribbons/Calculators/VfxCalculators/SoundPerformance/vfxSoundCustomFunctions.js'
import {
  generateVvecMop,
  generateVvecLat,
  generateVvecMca,
  generateVvecCapacity,
  generateVvecCurrent,
  generateVvecFv
} from '@/components/Ribbons/Calculators/VvecCalculators/vvecScheduleCustomFunctions'
import {
  generateVfxPressureDrop,
  generateVfxMinFlow,
  generateVfxMaxFlow
} from '@/components/Ribbons/Calculators/VfxCalculators/PressureDrop/vfxPressureDropCustomFunctions'
import {
  generateVvPressureDrop
} from '@/components/Ribbons/Calculators/VVCalculators/PressureDrop/vvPressureDropCustomFunctions'
import {
  generateVvhwcLwt,
  generateVvhwcLat,
  generateVvhwcFluidFlow,
  generateVvhwcFluidPressureDrop,
  generateVvhwcAirPressureDrop,
  generateVvhwcCoilCapacity,
  toggleVvhwcSolutionMethod
} from '@/components/Ribbons/Calculators/VvhwcCalculators/vvhwcPerformanceCustomFunctions'
import {
  generateVfxQuickSize
} from '@/components/Ribbons/Calculators/VfxCalculators/QuickSizing/vfxQuickSizingCustomFunctions'
import {
  generateVvQuickSize
} from '@/components/Ribbons/Calculators/VVCalculators/QuickSizing/vvQuickSizingCustomFunctions'

export const antecPrimary = getComputedStyle(document.documentElement).getPropertyValue('--antecPrimary').trim()
export const antecSecondary = getComputedStyle(document.documentElement).getPropertyValue('--antecSecondary').trim()
export const antecBlue = getComputedStyle(document.documentElement).getPropertyValue('--antecBlue').trim()

provideFluentDesignSystem().register(fluentCard(), fluentButton())
window.Office.onReady(async () => {
  new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount('#app')

  await store.dispatch('units/loadSelectedUnits').then(() => {
    CustomFunctions.associate('VV.MAXDISCHARGE', generateVvMaxDischarge)
    CustomFunctions.associate('VV.MAXRADIATED', generateVvMaxRadiated)
    CustomFunctions.associate('VV.MAXINLET', generateVvMaxInlet)
    CustomFunctions.associate('VFX.MAXDISCHARGE', generateVfxMaxDischarge)
    CustomFunctions.associate('VFX.MAXRADIATED', generateVfxMaxRadiated)
    CustomFunctions.associate('VFX.MAXINLET', generateVfxMaxInlet)
    CustomFunctions.associate('VVEC.MOP', generateVvecMop)
    CustomFunctions.associate('VVEC.LAT', generateVvecLat)
    CustomFunctions.associate('VVEC.MCA', generateVvecMca)
    CustomFunctions.associate('VVEC.CAPACITY', generateVvecCapacity)
    CustomFunctions.associate('VVEC.CURRENT', generateVvecCurrent)
    CustomFunctions.associate('VVEC.FV', generateVvecFv)
    CustomFunctions.associate('VFX.MINCATALOGFLOW', generateVfxMinFlow)
    CustomFunctions.associate('VFX.MAXCATALOGFLOW', generateVfxMaxFlow)
    CustomFunctions.associate('VFX.PRESSUREDROP', generateVfxPressureDrop)
    CustomFunctions.associate('VV.DIFFERENTIALPD', generateVvPressureDrop)
    CustomFunctions.associate('VVHWC.LAT', generateVvhwcLat)
    CustomFunctions.associate('VVHWC.LWT', generateVvhwcLwt)
    CustomFunctions.associate('VVHWC.FLUIDFLOW', generateVvhwcFluidFlow)
    CustomFunctions.associate('VVHWC.FLUIDPRESSUREDROP', generateVvhwcFluidPressureDrop)
    CustomFunctions.associate('VVHWC.COILCAPACITY', generateVvhwcCoilCapacity)
    CustomFunctions.associate('VVHWC.AIRPRESSUREDROP', generateVvhwcAirPressureDrop)
    CustomFunctions.associate('VVHWC.SOLUTIONMETHOD', toggleVvhwcSolutionMethod)
    CustomFunctions.associate('VFX.QUICKSIZE', generateVfxQuickSize)
    CustomFunctions.associate('VV.QUICKSIZE', generateVvQuickSize)
  })
})

window.Office.actions.associate( 'btnnegroom', btnnegroom )
window.Office.actions.associate( 'btnposroom', btnposroom )
window.Office.actions.associate( 'btnvfx', btnvfx )
window.Office.actions.associate( 'btnvv', btnvv )
window.Office.actions.associate( 'btnvvhwc', btnvvhwc )
window.Office.actions.associate( 'btnvvec', btnvvec )
window.Office.actions.associate( 'btnweb', btnweb )
window.Office.actions.associate( 'btnvvsoundcalc', btnvvsoundcalc)
window.Office.actions.associate( 'btnvvsizingcalc', btnvvsizingcalc)
window.Office.actions.associate( 'btnvfxsoundcalc', btnvfxsoundcalc)
window.Office.actions.associate( 'btnvfxoperatingcalc', btnvfxoperatingcalc)
window.Office.actions.associate( 'btnvfxsizingcalc', btnvfxsizingcalc)
window.Office.actions.associate( 'btnImperial', btnImperial)
window.Office.actions.associate( 'btnMetric', btnMetric)
window.Office.actions.associate( 'btnvvhwccalculator', btnvvhwccalc)
