<template>
  <base-modal
    :columns="COLUMNS_VVEC"
    :output-columns="OUTPUT_COLUMNS_VVEC"
    :validation-rules="VALIDATION_RULES_VVEC"
    :always-included-columns="alwaysIncludedColumns"
    :tab-name="VVEC_TAB_NAME"
  />
</template>

<script>
import { getUrl } from '../../common.js'
import BaseModal, { dialogCallback } from '@/components/Ribbons/CreateSchedules/BaseModal.vue'
import { VVEC_TAB_NAME } from '@/lib/constants'
import store from '@/store'

const url = getUrl()

// Function to open the dialog
window.btnvvec = async function (event) {
  try {
    await store.dispatch('units/loadSelectedUnits')
    await Office.context.ui.displayDialogAsync(
      url + '/#/VvecPopup?unitsType=' + store.getters['units/getSelectedUnits'],
      { height: 40, width: 45, displayInFrame: true, promptBeforeOpen: false },
      dialogCallback
    )
  } catch (error) {
    console.error('Failed to display dialog:', error)
  }
  event.completed()
}

export default {
  name: 'VvecPopup',
  components: { BaseModal },
  data () {
    return {
      COLUMNS_VVEC: {
        TOGGLE_ALL_COLUMNS: { id: 'Toggle All Columns' },
        model: { id: 'Model' },
        unitSize: { id: 'Unit Size' },
        heatingFlow: { id: `Heating Flow (${store.getters['units/getUnits']('flow')})` },
        voltagePhase: { id: 'Voltage/Phase' },
        enteringAirTemp: { id: `Entering Air Temperature (${store.getters['units/getUnits']('temperature')})` },
        maxFlow: { id: `MAX Flow (${store.getters['units/getUnits']('flow')})` },
        minFlow: { id: `MIN Flow (${store.getters['units/getUnits']('flow')})` },
        valveTag: { id: 'Valve Tag' },
        enclosureMounting: { id: 'Enclosure Mounting' },
        disconnectOption: { id: 'Disconnect Option' },
        fusing: { id: 'Fusing' },
        housingLiner: { id: 'Housing Liner' },
        quantity: { id: 'Quantity' }
      },
      OUTPUT_COLUMNS_VVEC: [],
      alwaysIncludedColumns: [
        'Model',
        'Unit Size',
        `Heating Flow (${store.getters['units/getUnits']('flow')})`,
        'Voltage/Phase',
        `Entering Air Temperature (${store.getters['units/getUnits']('temperature')})`
      ],
      VALIDATION_RULES_VVEC: {
        'Unit Size': '108, 110, 112, 114, 210, 212, 214, 312',
        'Enclosure Mounting': 'LH | Controls Enclosure LH (Left Side), RH | Controls Enclosure RH (Right Side)',
        'Voltage/Phase': '120-1 | 120V Single Phase, 208-1 | 208V Single Phase, 240-1 | 240V Single Phase, 277-1 | 277V Single Phase, 208-3 | 208V Three Phase, 480-3 | 480V Three Phase, 600-3 | 600V Three Phase',
        'Disconnect Option': 'None, IDSW | Door Interlock  Disconnect',
        'Fusing': 'None, FUS | Main Line Fusing',
        'Housing Liner': 'FF | Fiber Free Foam 3/4\", SM | Solid Metal Liner 3/4\", CRAF | Cleanroom Aluminum Foil Liner 5/8\"'
      },
      VVEC_TAB_NAME
    }
  },
  beforeCreate () {
    this.$store.dispatch('units/setSelectedUnits', this.$route.query.unitsType)
  }
}
</script>
