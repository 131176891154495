<template>
  <base-modal
    :columns="COLUMNS_VVHWC"
    :output-columns="OUTPUT_COLUMNS_VVHWC"
    :validation-rules="VALIDATION_RULES_VVHWC"
    :always-included-columns="alwaysIncludedColumns"
    :tab-name="VVHWC_TAB_NAME"
  />
</template>

<script>
import { getUrl } from '../../common.js'
import BaseModal, { dialogCallback } from '@/components/Ribbons/CreateSchedules/BaseModal.vue'
import { VVHWC_TAB_NAME } from '@/lib/constants'
import store from '@/store'

const url = getUrl()

// Function to open the dialog
window.btnvvhwc = async function (event) {
  try {
    await store.dispatch('units/loadSelectedUnits')
    await Office.context.ui.displayDialogAsync(
      url + '/#/VvhwcPopup?unitsType=' + store.getters['units/getSelectedUnits'],
      { height: 40, width: 45, displayInFrame: true, promptBeforeOpen: false },
      dialogCallback
    )
  } catch (error) {
    console.error('Failed to display dialog:', error)
  }
  event.completed()
}

export default {
  name: 'VvhwcPopup',
  components: { BaseModal },
  data () {
    return {
      COLUMNS_VVHWC: {
        TOGGLE_ALL_COLUMNS: { id: 'Toggle All Columns' },
        model: { id: 'Model' },
        unitSize: { id: 'Unit Size' },
        heatingFlow: { id: `Heating Flow (${store.getters['units/getUnits']('flow')})` },
        heatingCoilCapacity: { id: 'Heating Coil Capacity' },
        heatingCoilType: { id: 'Heating Coil Type' },
        fluidType: { id: 'Fluid Type' },
        enteringAirTemp: { id: `Entering Air Temperature (${store.getters['units/getUnits']('temperature')})` },
        enteringWaterTemp: { id: `Entering Water Temperature (${store.getters['units/getUnits']('temperature')})` },
        glycol: { id: 'Glycol (%)' },
        valveTag: { id: 'Valve Tag' },
        quantity: { id: 'Quantity' }
      },
      OUTPUT_COLUMNS_VVHWC: [],
      alwaysIncludedColumns: [
        'Model',
        'Unit Size',
        `Heating Flow (${store.getters['units/getUnits']('flow')})`,
        'Heating Coil Capacity',
        'Heating Coil Type',
        'Fluid Type',
        `Entering Air Temperature (${store.getters['units/getUnits']('temperature')})`,
        `Entering Water Temperature (${store.getters['units/getUnits']('temperature')})`,
        'Glycol (%)'
      ],
      VALIDATION_RULES_VVHWC: {
        'Unit Size': '108, 110, 112, 114, 210, 212, 214, 312, 314, 412',
        'Heating Coil Capacity': 'SC | Standard Capacity Hot Water Coil, HC | High Capacity Hot Water Coil, OSC | Oversized Standard Capacity Hot Water Coil, OHC | Oversized High Capacity Hot Water Coil',
        'Heating Coil Type': '1R | Right Hand One Row, 1L | Left Hand One Row, 2R | Right Hand Two Row, 2L | Left Hand Two Row, 3R | Right Hand Three Row, 3L | Left Hand Three Row, 4R | Right Hand Four Row, 4L | Left Hand Four Row',
        'Fluid Type': 'WTR | Water, PG | Propylene Glycol, EG | Ethylene Glycol'
      },
      VVHWC_TAB_NAME
    }
  },
  beforeCreate () {
    this.$store.dispatch('units/setSelectedUnits', this.$route.query.unitsType)
  }
}
</script>
