import { VVHWC_MODEL, VVHWC_FIXED_COL_WIDTH, VVHWC_SOLUTION_METHODS } from '@/lib/constants'
import store from '@/store'
import { ApiRequestHandler } from '@/apiHelper/apiRequestHandler'
import { setFixedColumnWidth } from '@/components/Ribbons/Calculators/commonFormatting'

async function fetchVVHWCApiResult (operation, unitSize, heatingFlow, heatingCoilCapacity, heatingCoilType, solutionMethod, fluidType, eat, ewt, coilCapacity, fluidFlow, lat, lwt, glycol) {
  await store.dispatch('units/loadSelectedUnits')
  const data =
    {
      operation,
      input: {
        model: VVHWC_MODEL,
        units: store.getters['units/getSelectedUnits'],
        unitSize,
        heatingFlow,
        heatingCoilCapacity,
        heatingCoilType,
        solutionMethod,
        fluidType,
        eat,
        ewt,
        coilCapacity,
        fluidFlow,
        lat,
        lwt,
        glycol
      }
    }

  const apiHandler = new ApiRequestHandler()
  const response = await apiHandler.postData('vvhwc/excel/coilPerformance', data)

  if (response.data[0].error === null) {
    return response.data[0].result
  } else {
    console.error(response.data[0].error)
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, response.data[0].error)
  }
}

/**
 * @customfunction
 */
export async function toggleVvhwcSolutionMethod (tableName, solutionMethod) {
  const temperatureUnits = store.getters['units/getUnits']('temperature')
  const pressureUnits = store.getters['units/getUnits']('pressure')
  const fluidPressureDropUnits = store.getters['units/getUnits']('fluidPressureDrop')
  const coilCapacity = store.getters['units/getUnits']('coilCapacity')
  const fluidFlow = store.getters['units/getUnits']('fluidFlow')

  await Excel.run(async (context) => {
    const worksheet = context.workbook.worksheets.getActiveWorksheet()
    const table = worksheet.tables.getItem(tableName)
    const dataRange = table.getDataBodyRange()
    const tableData = [
      ['', '', ''],
      ['', '', ''],
      ['', '', ''],
      ['', '', ''],
      ['Fluid Pressure Drop', '', fluidPressureDropUnits],
      ['Air Pressure Drop', '', pressureUnits]
    ]

    const fluidFlowRow = ['Fluid Flow', '', fluidFlow]
    const latRow = ['Leaving Air Temperature', '', temperatureUnits]
    const lwtRow = ['Leaving Water Temperature', '', temperatureUnits]
    const coilCapacityRow = ['Coil Capacity', '', coilCapacity]

    const unitSizeCell = dataRange.getCell(-8, 1)
    const heatingFlowCell = dataRange.getCell(-7, 1)
    const heatingCoilCapCell = dataRange.getCell(-6, 1)
    const heatingCoilTypeCell = dataRange.getCell(-5, 1)
    const fluidTypeCell = dataRange.getCell(-4, 1)
    const glycolCell = dataRange.getCell(-3, 1)
    const eatCell = dataRange.getCell(-2, 1)
    const ewtCell = dataRange.getCell(-1, 1)
    const additionalInputCell = dataRange.getCell(0, 1)

    // Load formulas
    unitSizeCell.load('address')
    heatingFlowCell.load('address')
    heatingCoilCapCell.load('address')
    heatingCoilTypeCell.load('address')
    fluidTypeCell.load('address')
    glycolCell.load('address')
    eatCell.load('address')
    ewtCell.load('address')
    additionalInputCell.load('address')
    dataRange.load('rowCount')

    await context.sync()

    const unitSizeAddress = unitSizeCell.address
    const heatingFlowAddress = heatingFlowCell.address
    const heatingCoilCapAddress = heatingCoilCapCell.address
    const heatingCoilTypeAddress = heatingCoilTypeCell.address
    const fluidTypeAddress = fluidTypeCell.address
    const glycolAddress = glycolCell.address
    const eatAddress = eatCell.address
    const ewtAddress = ewtCell.address
    const additionalInputAddress = additionalInputCell.address

    switch (solutionMethod) {
    case VVHWC_SOLUTION_METHODS.FLOW:
      tableData[0] = fluidFlowRow
      tableData[1] = latRow
      tableData[1][1] = [[`=AntecControls.VVHWC.LAT(${unitSizeAddress}, ${heatingFlowAddress}, ${heatingCoilCapAddress}, ${heatingCoilTypeAddress}, "${solutionMethod}", ${fluidTypeAddress}, ${eatAddress}, ${ewtAddress}, 0, ${additionalInputAddress}, 0, 0, ${glycolAddress})`]]
      tableData[2] = lwtRow
      tableData[2][1] = [[`=AntecControls.VVHWC.LWT(${unitSizeAddress}, ${heatingFlowAddress}, ${heatingCoilCapAddress}, ${heatingCoilTypeAddress}, "${solutionMethod}", ${fluidTypeAddress}, ${eatAddress}, ${ewtAddress}, 0, ${additionalInputAddress}, 0, 0, ${glycolAddress})`]]
      tableData[3] = coilCapacityRow
      tableData[3][1] = [[`=AntecControls.VVHWC.COILCAPACITY(${unitSizeAddress}, ${heatingFlowAddress}, ${heatingCoilCapAddress}, ${heatingCoilTypeAddress}, "${solutionMethod}", ${fluidTypeAddress}, ${eatAddress}, ${ewtAddress}, 0, ${additionalInputAddress}, 0, 0, ${glycolAddress})`]]
      tableData[4][1] = [[`=AntecControls.VVHWC.FLUIDPRESSUREDROP(${unitSizeAddress}, ${heatingFlowAddress}, ${heatingCoilCapAddress}, ${heatingCoilTypeAddress}, "${solutionMethod}", ${fluidTypeAddress}, ${eatAddress}, ${ewtAddress}, 0, ${additionalInputAddress}, 0, 0, ${glycolAddress})`]]
      tableData[5][1] = [[`=AntecControls.VVHWC.AIRPRESSUREDROP(${unitSizeAddress}, ${heatingFlowAddress}, ${heatingCoilCapAddress}, ${heatingCoilTypeAddress}, "${solutionMethod}", ${fluidTypeAddress}, ${eatAddress}, ${ewtAddress}, 0, ${additionalInputAddress}, 0, 0, ${glycolAddress})`]]
      break
    case VVHWC_SOLUTION_METHODS.LAT:
      tableData[0] = latRow
      tableData[1] = fluidFlowRow
      tableData[1][1] = [[`=AntecControls.VVHWC.FLUIDFLOW(${unitSizeAddress}, ${heatingFlowAddress}, ${heatingCoilCapAddress}, ${heatingCoilTypeAddress}, "${solutionMethod}", ${fluidTypeAddress}, ${eatAddress}, ${ewtAddress}, 0, 0, ${additionalInputAddress}, 0, ${glycolAddress})`]]
      tableData[2] = lwtRow
      tableData[2][1] = [[`=AntecControls.VVHWC.LWT(${unitSizeAddress}, ${heatingFlowAddress}, ${heatingCoilCapAddress}, ${heatingCoilTypeAddress}, "${solutionMethod}", ${fluidTypeAddress}, ${eatAddress}, ${ewtAddress}, 0, 0, ${additionalInputAddress}, 0, ${glycolAddress})`]]
      tableData[3] = coilCapacityRow
      tableData[3][1] = [[`=AntecControls.VVHWC.COILCAPACITY(${unitSizeAddress}, ${heatingFlowAddress}, ${heatingCoilCapAddress}, ${heatingCoilTypeAddress}, "${solutionMethod}", ${fluidTypeAddress}, ${eatAddress}, ${ewtAddress}, 0, 0, ${additionalInputAddress}, 0, ${glycolAddress})`]]
      tableData[4][1] = [[`=AntecControls.VVHWC.FLUIDPRESSUREDROP(${unitSizeAddress}, ${heatingFlowAddress}, ${heatingCoilCapAddress}, ${heatingCoilTypeAddress}, "${solutionMethod}", ${fluidTypeAddress}, ${eatAddress}, ${ewtAddress}, 0, 0, ${additionalInputAddress}, 0, ${glycolAddress})`]]
      tableData[5][1] = [[`=AntecControls.VVHWC.AIRPRESSUREDROP(${unitSizeAddress}, ${heatingFlowAddress}, ${heatingCoilCapAddress}, ${heatingCoilTypeAddress}, "${solutionMethod}", ${fluidTypeAddress}, ${eatAddress}, ${ewtAddress}, 0, 0, ${additionalInputAddress}, 0, ${glycolAddress})`]]
      break
    case VVHWC_SOLUTION_METHODS.LWT:
      tableData[0] = lwtRow
      tableData[1] = fluidFlowRow
      tableData[1][1] = [[`=AntecControls.VVHWC.FLUIDFLOW(${unitSizeAddress}, ${heatingFlowAddress}, ${heatingCoilCapAddress}, ${heatingCoilTypeAddress}, "${solutionMethod}", ${fluidTypeAddress}, ${eatAddress}, ${ewtAddress}, 0, 0, 0, ${additionalInputAddress}, ${glycolAddress})`]]
      tableData[2] = latRow
      tableData[2][1] = [[`=AntecControls.VVHWC.LAT(${unitSizeAddress}, ${heatingFlowAddress}, ${heatingCoilCapAddress}, ${heatingCoilTypeAddress}, "${solutionMethod}", ${fluidTypeAddress}, ${eatAddress}, ${ewtAddress}, 0, 0, 0, ${additionalInputAddress}, ${glycolAddress})`]]
      tableData[3] = coilCapacityRow
      tableData[3][1] = [[`=AntecControls.VVHWC.COILCAPACITY(${unitSizeAddress}, ${heatingFlowAddress}, ${heatingCoilCapAddress}, ${heatingCoilTypeAddress}, "${solutionMethod}", ${fluidTypeAddress}, ${eatAddress}, ${ewtAddress}, 0, 0, 0, ${additionalInputAddress}, ${glycolAddress})`]]
      tableData[4][1] = [[`=AntecControls.VVHWC.FLUIDPRESSUREDROP(${unitSizeAddress}, ${heatingFlowAddress}, ${heatingCoilCapAddress}, ${heatingCoilTypeAddress}, "${solutionMethod}", ${fluidTypeAddress}, ${eatAddress}, ${ewtAddress}, 0, 0, 0, ${additionalInputAddress}, ${glycolAddress})`]]
      tableData[5][1] = [[`=AntecControls.VVHWC.AIRPRESSUREDROP(${unitSizeAddress}, ${heatingFlowAddress}, ${heatingCoilCapAddress}, ${heatingCoilTypeAddress}, "${solutionMethod}", ${fluidTypeAddress}, ${eatAddress}, ${ewtAddress}, 0, 0, 0, ${additionalInputAddress}, ${glycolAddress})`]]
      break
    case VVHWC_SOLUTION_METHODS.CAPACITY:
      tableData[0] = coilCapacityRow
      tableData[1] = fluidFlowRow
      tableData[1][1] = [[`=AntecControls.VVHWC.FLUIDFLOW(${unitSizeAddress}, ${heatingFlowAddress}, ${heatingCoilCapAddress}, ${heatingCoilTypeAddress}, "${solutionMethod}", ${fluidTypeAddress}, ${eatAddress}, ${ewtAddress}, ${additionalInputAddress}, 0, 0, 0, ${glycolAddress})`]]
      tableData[2] = latRow
      tableData[2][1] = [[`=AntecControls.VVHWC.LAT(${unitSizeAddress}, ${heatingFlowAddress}, ${heatingCoilCapAddress}, ${heatingCoilTypeAddress}, "${solutionMethod}", ${fluidTypeAddress}, ${eatAddress}, ${ewtAddress}, ${additionalInputAddress}, 0, 0, 0, ${glycolAddress})`]]
      tableData[3] = lwtRow
      tableData[3][1] = [[`=AntecControls.VVHWC.LWT(${unitSizeAddress}, ${heatingFlowAddress}, ${heatingCoilCapAddress}, ${heatingCoilTypeAddress}, "${solutionMethod}", ${fluidTypeAddress}, ${eatAddress}, ${ewtAddress}, ${additionalInputAddress}, 0, 0, 0, ${glycolAddress})`]]
      tableData[4][1] = [[`=AntecControls.VVHWC.FLUIDPRESSUREDROP(${unitSizeAddress}, ${heatingFlowAddress}, ${heatingCoilCapAddress}, ${heatingCoilTypeAddress}, "${solutionMethod}", ${fluidTypeAddress}, ${eatAddress}, ${ewtAddress}, ${additionalInputAddress}, 0, 0, 0, ${glycolAddress})`]]
      tableData[5][1] = [[`=AntecControls.VVHWC.AIRPRESSUREDROP(${unitSizeAddress}, ${heatingFlowAddress}, ${heatingCoilCapAddress}, ${heatingCoilTypeAddress}, "${solutionMethod}", ${fluidTypeAddress}, ${eatAddress}, ${ewtAddress}, ${additionalInputAddress}, 0, 0, 0, ${glycolAddress})`]]
      break
    }

    tableData.forEach((row, rowIndex) => {
      row.forEach( (col, colIndex) => {
        if (colIndex !== 1) {
          dataRange.getCell(rowIndex, colIndex).values = tableData[rowIndex][colIndex]
        } else {
          dataRange.getCell(rowIndex, colIndex).formulas = tableData[rowIndex][colIndex]
        }
      })
    })

    dataRange.getCell(0, 1).formulas = ''

    // Style column width
    dataRange.format.autofitColumns()
    setFixedColumnWidth(dataRange, tableData[0].length, VVHWC_FIXED_COL_WIDTH)
  })
  return ''
}

export function generateVvhwcLat (...args) {
  return fetchVVHWCApiResult('LAT', ...args)
}
export function generateVvhwcLwt (...args) {
  return fetchVVHWCApiResult('LWT', ...args)
}
export function generateVvhwcFluidFlow (...args) {
  return fetchVVHWCApiResult('FluidFlow', ...args)
}
export function generateVvhwcFluidPressureDrop (...args) {
  return fetchVVHWCApiResult('FluidPressureDrop', ...args)
}
export function generateVvhwcCoilCapacity (...args) {
  return fetchVVHWCApiResult('CoilCapacity', ...args)
}
export function generateVvhwcAirPressureDrop (...args) {
  return fetchVVHWCApiResult('AirPressureDrop', ...args)
}
