<template>
  <base-modal
    :columns="COLUMNS_VV"
    :output-columns="OUTPUT_COLUMNS_VV"
    :validation-rules="VALIDATION_RULES_VV"
    :always-included-columns="alwaysIncludedColumns"
    :tab-name="VV_TAB_NAME"
  />
</template>

<script>
import { getUrl } from '../../common.js'
import BaseModal, { dialogCallback } from '@/components/Ribbons/CreateSchedules/BaseModal.vue'
import { VV_TAB_NAME } from '@/lib/constants'
import store from '@/store'

const url = getUrl()

window.btnvv = async function (event) {
  try {
    await store.dispatch('units/loadSelectedUnits')
    await Office.context.ui.displayDialogAsync(
      url + '/#/VvPopup?unitsType=' + store.getters['units/getSelectedUnits'],
      { height: 50, width: 50, displayInFrame: true, promptBeforeOpen: false },
      dialogCallback
    )
  } catch (error) {
    console.error('Failed to display dialog:', error)
  }
  event.completed()
}

export default {
  name: 'VvPopup',
  components: { BaseModal },
  data () {
    return {
      COLUMNS_VV: {
        TOGGLE_ALL_COLUMNS: { id: 'Toggle All Columns' },
        model: { id: 'Model' },
        unitSize: { id: 'Unit Size' },
        maxFlow: { id: `MAX Flow (${store.getters['units/getUnits']('flow')})` },
        minFlow: { id: `MIN Flow (${store.getters['units/getUnits']('flow')})` },
        pressureDrop: { id: `Pressure Drop (${store.getters['units/getUnits']('pressure')})` },
        valveTag: { id: 'Valve Tag' },
        room: { id: 'Room' },
        application: { id: 'Application' },
        pressureRange: { id: 'Pressure Range' },
        quantity: { id: 'Quantity' }
      },
      OUTPUT_COLUMNS_VV: [
        { id: 'MAX Discharge Sound Power Levels', formula: '=AntecControls.VV.MAXDISCHARGE(B2, C2, E2)' },
        { id: 'MAX Radiated Sound Power Levels', formula: '=AntecControls.VV.MAXRADIATED(B2, C2, E2)' },
        { id: 'MAX Inlet Sound Power Levels', formula: '=AntecControls.VV.MAXINLET(B2, C2, E2)' }
      ],
      VALIDATION_RULES_VV: {
        'Application': 'SAV | Supply Air Valve, GEV | General Exhaust Valve, FEV | Fume Hood Exhaust Valve, RAV | Return Air Valve, OEV | Other Exhaust Valve',
        'Unit Size': '108, 110, 112, 114, 210, 212, 214, 312, 314, 412, 414',
        'Pressure Range': 'L | Low Pressure, M | Medium Pressure'
      },
      alwaysIncludedColumns: [
        'Model',
        'Unit Size',
        `MAX Flow (${store.getters['units/getUnits']('flow')})`,
        `MIN Flow (${store.getters['units/getUnits']('flow')})`,
        `Pressure Drop (${store.getters['units/getUnits']('pressure')})`
      ],
      VV_TAB_NAME
    }
  },
  beforeCreate () {
    this.$store.dispatch('units/setSelectedUnits', this.$route.query.unitsType)
  }
}
</script>

