<template>
  <div>
    <div class="dialog-content">
      <!-- Toggle All Columns Checkbox -->
      <div class="popup-column">
        <label>
          <input
            id="TOGGLE_ALL_COLUMNS"
            v-model="toggleAll"
            type="checkbox"
            @change="toggleAllColumns"
          />
          Toggle All Columns
        </label>
      </div>
      <!-- Columns Selection -->
      <div
        v-for="(column, index) in filteredColumns"
        :key="index"
        class="popup-column checkbox-container"
      >
        <label>
          <input
            v-if="alwaysIncludedColumns.includes(column.id)"
            disabled
            type="checkbox"
            checked
            class="always-included-checkbox"
          />
          <input
            v-else
            :id="column.id"
            v-model="selectedColumnIds"
            type="checkbox"
            :value="column.id"
          />
          {{ column.id }}
        </label>
        <br />
      </div>
      <!-- Dropdown for SolutionMethod -->
      <div
        v-if="tabName === VVEC_TAB_NAME"
        class="dropdown-container"
      >
        <label for="solution-method">
          Solution Method
        </label>
        <select
          id="solution-method"
          v-model="solutionMethod"
        >
          <option value="Capacity">
            Capacity
          </option>
          <option value="LAT">
            LAT
          </option>
        </select>
      </div>
      <div
        v-if="tabName === VVHWC_TAB_NAME"
        class="dropdown-container"
      >
        <label for="solution-method">
          Solution Method
        </label>
        <select
          id="solution-method"
          v-model="solutionMethod"
        >
          <option value="Capacity">
            Capacity
          </option>
          <option value="LAT">
            LAT
          </option>
          <option value="LWT">
            LWT
          </option>
          <option value="Flow">
            Flow
          </option>
        </select>
      </div>
    </div>
    <button
      id="send-button"
      @click="sendMessage"
    >
      Generate Schedule
    </button>
  </div>
</template>

<script>
import { TOGGLE_ALL_COLUMNS, VVEC_TAB_NAME, VVHWC_TAB_NAME, VFX_PRESSURE_DROP_HEADER } from '@/lib/constants.js'
import { applyFormatting, setDropdownAndOptionalColour } from '@/components/Ribbons/Calculators/commonFormatting'
import { addSuffixToSheetName } from '@/components/common'
import { antecBlue } from '@/main'

const EMPTY_ROW_COUNT = 9

let dialog = null

// Callback function for the dialog
export function dialogCallback (asyncResult) {
  if (asyncResult.status === Office.AsyncResultStatus.Failed) {
    switch (asyncResult.error.code) {
    case 12004:
      console.error('Domain is not trusted')
      break
    case 12005:
      console.error('HTTPS is required')
      break
    case 12007:
      console.error('A dialog is already opened')
      break
    default:
      console.error(asyncResult.error.message)
      break
    }
  } else {
    dialog = asyncResult.value
    dialog.addEventHandler(Office.EventType.DialogMessageReceived, messageHandler)
  }
}

// Function to handle messages from the dialog
function messageHandler (arg) {
  dialog.close()

  const parsedJson = JSON.parse(arg.message)
  console.log('Message Handler:', parsedJson)

  createTable(
    parsedJson.tabName,
    parsedJson.columns,
    parsedJson.outputColumns,
    parsedJson.validationRules,
    parsedJson.selectedColumns
  )
}

// Return the Excel column letter given a column index where the first column is A at index 1
function getColumnLetter (index) {
  const asciiAValue = 65
  let letter = ''
  while (index >= 0) {
    letter = String.fromCharCode((index % 26) + asciiAValue) + letter // ex: 0 = A, 1 = B
    index = Math.floor(index / 26) - 1
  }
  return letter
}

async function createTable (tabName, columns, outputColumns, validationRules, selectedColumns) {
  try {
    await Excel.run(async (context) => {
      const sheetName = await addSuffixToSheetName(context, tabName)
      const sheet = context.workbook.worksheets.add(sheetName)
      sheet.activate()

      const filteredHeaders = selectedColumns.concat(outputColumns.map((column) => column.id))
      const endColumn = getColumnLetter(filteredHeaders.length - 1)
      const headerRange = sheet.getRange(`A1:${endColumn}1`)
      headerRange.values = [filteredHeaders]
      headerRange.format.font.bold = true
      headerRange.format.font.color = 'black'
      headerRange.format.horizontalAlignment = 'center'
      headerRange.format.verticalAlignment = 'center'

      const tableRange = sheet.getRange(`A1:${endColumn}10`)
      tableRange.format.fill.color = 'lightblue'
      tableRange.format.horizontalAlignment = 'center'
      tableRange.format.verticalAlignment = 'center'
      tableRange.load('address')

      await context.sync()

      // Create the table
      const table = sheet.tables.add(tableRange, true)
      const allColumnsRange = table.getDataBodyRange()
      allColumnsRange.format.fill.color = 'lightblue'
      table.showFilterButton = false

      applyFormatting(headerRange, 'black', 'Thin')
      applyFormatting(tableRange, 'black', 'Thin')
      applyFormatting(allColumnsRange, 'black', 'Thin')

      // Apply formulas to columns
      filteredHeaders.forEach((header, index) => {
        const columnRange = table.columns.getItemAt(index).getRange()
        columnRange.format.wrapText = true

        // Find the output column and apply its formula if it exists
        const outputColumn = outputColumns.find((column) => column.id === header)
        if (outputColumn && outputColumn.formula) {
          const formulaRange = sheet.getRangeByIndexes(1, index, 1, 1)

          if (outputColumn.formula.includes('PD')) {
            const pressureDropIndex = filteredHeaders.findIndex(header => header.includes(VFX_PRESSURE_DROP_HEADER))
            if (pressureDropIndex !== -1) {
              outputColumn.formula = outputColumn.formula.replace('PD', getColumnLetter(pressureDropIndex) + '2')
            }
          }

          formulaRange.formulas = Array.from({ length: 1 }, () => [outputColumn.formula])
        }

        if (validationRules[header]) {
          const validationRange = table.columns.getItemAt(index).getRange().getOffsetRange(1, 0).getResizedRange(-1, 0) // Add the validation to all rows except the header
          const headerCell = table.columns.getItemAt(index).getRange().getCell(0, 0)

          setDropdownAndOptionalColour(validationRange, validationRules[header], 'lightgray')
          headerCell.format.fill.color = 'lightgray'
        }

        if (outputColumns.map((col) => col.id).includes(header)) {
          columnRange.format.fill.color = antecBlue
        }
      })

      const cell = sheet.getRange('A2:A10')
      const model = tabName.split(' ')[0]

      cell.values = Array(EMPTY_ROW_COUNT).fill([model])

      table.getRange().format.autofitColumns()
      await context.sync()
    })
  } catch (error) {
    console.error('Error creating Table:', error)
  }
}

export default {
  name: 'BaseModal',
  props: {
    columns: {
      type: Object,
      required: true,
      default: () => ({})
    },
    outputColumns: {
      type: Array,
      required: true,
      default: () => []
    },
    validationRules: {
      type: Object,
      required: false,
      default: () => ({})
    },
    tabName: {
      type: String,
      required: false,
      default: ''
    },
    alwaysIncludedColumns: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      toggleAll: false,
      solutionMethod: 'LWT',
      selectedColumnIds: [...this.alwaysIncludedColumns],
      outputColumnsIDs: [...this.outputColumns],
      VVEC_TAB_NAME,
      VVHWC_TAB_NAME,
      compiledConfigColumns: {
        LAT: {
          inputColumns: [`Leaving Air Temperature (${this.$store.getters['units/getUnits']('temperature')})`],
          VVHWC: {
            outputColumns: [
              {
                id: `Fluid Flow (${this.$store.getters['units/getUnits']('fluidFlow')})`,
                formula: '=AntecControls.VVHWC.FLUIDFLOW(B2,C2,D2,E2,"LAT",G2,H2,I2,0,0,F2,0,J2)'
              },
              {
                id: `Leaving Water Temperature (${this.$store.getters['units/getUnits']('temperature')})`,
                formula: '=AntecControls.VVHWC.LWT(B2,C2,D2,E2,"LAT",G2,H2,I2,0,0,F2,0,J2)'
              },
              {
                id: `Coil Capacity (${this.$store.getters['units/getUnits']('coilCapacity')})`,
                formula: '=AntecControls.VVHWC.COILCAPACITY(B2,C2,D2,E2,"LAT",G2,H2,I2,0,0,F2,0,J2)'
              },
              {
                id: `Fluid Pressure Drop (${this.$store.getters['units/getUnits']('fluidPressureDrop')})`,
                formula: '=AntecControls.VVHWC.FLUIDPRESSUREDROP(B2,C2,D2,E2,"LAT",G2,H2,I2,0,0,F2,0,J2)'
              },
              {
                id: `Air Pressure Drop (${this.$store.getters['units/getUnits']('pressure')})`,
                formula: '=AntecControls.VVHWC.AIRPRESSUREDROP(B2,C2,D2,E2,"LAT",G2,H2,I2,0,0,F2,0,J2)'
              }
            ]
          },
          VVEC: {
            outputColumns: [
              {
                id: 'Face Velocity (FPM)',
                formula: '=AntecControls.VVEC.FV(B2,C2,D2,E2,F2,0,"LAT")'
              },
              {
                id: `Coil Capacity (${this.$store.getters['units/getUnits']('coilCapacity')})`,
                formula: '=AntecControls.VVEC.CAPACITY(B2,C2,D2,E2,F2,0,"LAT")'
              }
            ]
          }
        },
        Capacity: {
          inputColumns: [`Coil Capacity (${this.$store.getters['units/getUnits']('coilCapacity')})`],
          VVHWC: {
            outputColumns: [
              {
                id: `Fluid Flow (${this.$store.getters['units/getUnits']('fluidFlow')})`,
                formula: '=AntecControls.VVHWC.FLUIDFLOW(B2,C2,D2,E2,"Capacity",G2,H2,I2,F2,0,0,0,J2)'
              },
              {
                id: `Leaving Air Temperature (${this.$store.getters['units/getUnits']('temperature')})`,
                formula: '=AntecControls.VVHWC.LAT(B2,C2,D2,E2,"Capacity",G2,H2,I2,F2,0,0,0,J2)'
              },
              {
                id: `Leaving Water Temperature (${this.$store.getters['units/getUnits']('temperature')})`,
                formula: '=AntecControls.VVHWC.LWT(B2,C2,D2,E2,"Capacity",G2,H2,I2,F2,0,0,0,J2)'
              },
              {
                id: `Fluid Pressure Drop (${this.$store.getters['units/getUnits']('fluidPressureDrop')})`,
                formula: '=AntecControls.VVHWC.FLUIDPRESSUREDROP(B2,C2,D2,E2,"Capacity",G2,H2,I2,F2,0,0,0,J2)'
              },
              {
                id: `Air Pressure Drop (${this.$store.getters['units/getUnits']('pressure')})`,
                formula: '=AntecControls.VVHWC.AIRPRESSUREDROP(B2,C2,D2,E2,"Capacity",G2,H2,I2,F2,0,0,0,J2)'
              }
            ]
          },
          VVEC: {
            outputColumns: [
              {
                id: 'Face Velocity (FPM)',
                formula: '=AntecControls.VVEC.FV(B2,C2,D2,E2,0,F2,"Capacity")'
              },
              {
                id: `Leaving Air Temperature (${this.$store.getters['units/getUnits']('temperature')})`,
                formula: '=AntecControls.VVEC.LAT(B2,C2,D2,E2,0,F2)'
              },
              {
                id: 'Coil Current (A)',
                formula: '=AntecControls.VVEC.CURRENT(B2,C2,D2,E2,0,F2)'
              },
              {
                id: 'MOP',
                formula: '=AntecControls.VVEC.MOP(B2,C2,D2,E2,0,F2)'
              },
              {
                id: 'MCA',
                formula: '=AntecControls.VVEC.MCA(B2,C2,D2,E2,0,F2)'
              }
            ]
          }
        },
        LWT: {
          inputColumns: [`Leaving Water Temperature (${this.$store.getters['units/getUnits']('temperature')})`],
          VVHWC: {
            outputColumns: [
              {
                id: `Fluid Flow (${this.$store.getters['units/getUnits']('fluidFlow')})`,
                formula: '=AntecControls.VVHWC.FLUIDFLOW(B2,C2,D2,E2,"LWT",G2,H2,I2,0,0,0,F2,J2)'
              },
              {
                id: `Leaving Air Temperature (${this.$store.getters['units/getUnits']('temperature')})`,
                formula: '=AntecControls.VVHWC.LAT(B2,C2,D2,E2,"LWT",G2,H2,I2,0,0,0,F2,J2)'
              },
              {
                id: `Coil Capacity (${this.$store.getters['units/getUnits']('coilCapacity')})`,
                formula: '=AntecControls.VVHWC.COILCAPACITY(B2,C2,D2,E2,"LWT",G2,H2,I2,0,0,0,F2,J2)'
              },
              {
                id: `Fluid Pressure Drop (${this.$store.getters['units/getUnits']('fluidPressureDrop')})`,
                formula: '=AntecControls.VVHWC.FLUIDPRESSUREDROP(B2,C2,D2,E2,"LWT",G2,H2,I2,0,0,0,F2,J2)'
              },
              {
                id: `Air Pressure Drop (${this.$store.getters['units/getUnits']('pressure')})`,
                formula: '=AntecControls.VVHWC.AIRPRESSUREDROP(B2,C2,D2,E2,"LWT",G2,H2,I2,0,0,0,F2,J2)'
              }
            ]
          },
          VVEC: {
            outputColumns: []
          }
        },
        Flow: {
          inputColumns: [`Fluid Flow (${this.$store.getters['units/getUnits']('fluidFlow')})`],
          VVHWC: {
            outputColumns: [
              {
                id: `Leaving Air Temperature (${this.$store.getters['units/getUnits']('temperature')})`,
                formula: '=AntecControls.VVHWC.LAT(B2,C2,D2,E2,"Flow",G2,H2,I2,0,F2,0,0,J2)'
              },
              {
                id: `Leaving Water Temperature (${this.$store.getters['units/getUnits']('temperature')})`,
                formula: '=AntecControls.VVHWC.LWT(B2,C2,D2,E2,"Flow",G2,H2,I2,0,F2,0,0,J2)'
              },
              {
                id: `Coil Capacity (${this.$store.getters['units/getUnits']('coilCapacity')})`,
                formula: '=AntecControls.VVHWC.COILCAPACITY(B2,C2,D2,E2,"Flow",G2,H2,I2,0,F2,0,0,J2)'
              },
              {
                id: `Fluid Pressure Drop (${this.$store.getters['units/getUnits']('fluidPressureDrop')})`,
                formula: '=AntecControls.VVHWC.FLUIDPRESSUREDROP(B2,C2,D2,E2,"Flow",G2,H2,I2,0,F2,0,0,J2)'
              },
              {
                id: `Air Pressure Drop (${this.$store.getters['units/getUnits']('pressure')})`,
                formula: '=AntecControls.VVHWC.AIRPRESSUREDROP(B2,C2,D2,E2,"Flow",G2,H2,I2,0,F2,0,0,J2)'
              }
            ]
          },
          VVEC: {
            outputColumns: []
          }
        }
      }
    }
  },
  computed: {
    filteredColumns () {
      return Object.values(this.columns).filter((column) => (
        (column.id !== TOGGLE_ALL_COLUMNS) && !this.outputColumns.includes(column.id)
      ))
    }
  },
  mounted () {
    this.initializeSolutionMethod()
  },
  methods: {
    initializeSolutionMethod () {
      // Set default solution method based on tabName
      switch (this.tabName) {
      case VVEC_TAB_NAME:
        this.solutionMethod = 'LAT'
        break
      case VVHWC_TAB_NAME:
        this.solutionMethod = 'LWT'
        break
      default:
        this.solutionMethod = ''
      }
    },
    toggleAllColumns () {
      this.selectedColumnIds = this.toggleAll
        ? this.filteredColumns.map((column) => column.id)
        : [...this.alwaysIncludedColumns]
    },
    updateColumns () {
      const compiledConfig = this.compiledConfigColumns[this.solutionMethod]

      if (!compiledConfig) {
        return
      }

      // Define columns and formulas based on the selected solution method and tabName
      const newOutputColumns = (this.tabName === VVHWC_TAB_NAME) ? compiledConfig.VVHWC.outputColumns : compiledConfig.VVEC.outputColumns
      const newInputColumnIds = compiledConfig.inputColumns

      // Update selectedColumnIds and outputColumnsIDs based on new columns
      this.selectedColumnIds = [
        ...this.selectedColumnIds.slice(0, 5),
        ...newInputColumnIds,
        ...this.selectedColumnIds.slice(5)
      ]

      this.outputColumnsIDs = [
        ...this.outputColumns.filter((col) => !newOutputColumns.some((newCol) => newCol.id === col.id)),
        ...newOutputColumns
      ]

      this.selectedColumnIds = [
        ...new Set([
          ...this.selectedColumnIds,
          ...this.alwaysIncludedColumns
        ])
      ]
    },
    sendMessage () {
      this.updateColumns()
      const message = JSON.stringify({
        tabName: this.tabName,
        columns: this.columns,
        outputColumns: this.outputColumnsIDs,
        validationRules: this.validationRules,
        selectedColumns: this.selectedColumnIds
      })
      window.Office.context.ui?.messageParent(message) ?? console.error('Unable to send message to parent window. Make sure this code is running inside an Office Add-in dialog box.')
    }
  }
}
</script>
