import {
  applyFormatting,
  applyOuterBorder,
  applyTitleFormatting,
  setDropdownAndOptionalColour,
  setFixedColumnWidth
} from '@/components/Ribbons/Calculators/commonFormatting.js'
import { antecSecondary, antecPrimary } from '@/main'
import {
  VVHWC_FLUID_TYPES_WITH_DESCRIPTIONS,
  VVHWC_HEATING_COIL_CAPACITIES_WITH_DESCRIPTIONS,
  VVHWC_HEATING_COIL_TYPES_WITH_DESCRIPTIONS,
  VVHWC_UNIT_SIZES_DROPDOWN,
  VVHWC_FIXED_COL_WIDTH,
  VVHWC_SOLUTION_METHODS
} from '@/lib/constants'
import store from '@/store'

export function btnvvhwccalc (event) {
  window.Excel.run(async (context) => {
    try {
      await store.dispatch('units/loadSelectedUnits')
      const temperatureUnits = store.getters['units/getUnits']('temperature')
      const flowUnits = store.getters['units/getUnits']('flow')

      // Define the table structure
      const tableData = [
        ['VVHWC Performance Calculator', '', ''],
        ['', '', ''],
        ['Solution Method', VVHWC_SOLUTION_METHODS.FLOW, ''],
        ['', '', ''],
        ['Unit Size', '', ''],
        ['Heating Flow', '', flowUnits],
        ['Heating Coil Cap.', '', ''],
        ['Heating Coil Type', '', ''],
        ['Fluid Type', '', ''],
        ['Glycol', '', '%'],
        ['Entering Air Temperature', '', temperatureUnits],
        ['Entering Water Temperature', '', temperatureUnits],
        ['', '', ''],
        ['', '', ''],
        ['', '', ''],
        ['', '', ''],
        ['', '', ''],
        ['', '', ''],
        ['', '', '']
      ]

      const worksheet = context.workbook.worksheets.getActiveWorksheet()
      const activeCell = context.workbook.getActiveCell()
      const numRows = tableData.length
      const numCols = tableData[0].length
      const targetRange = activeCell.getResizedRange(numRows - 1, numCols - 1)
      const numOutputRows = 6
      const numInputRows = numRows - numOutputRows

      const solutionMethodCell = targetRange.getCell(2, 1)
      const outputCell = targetRange.getCell(3, 1)
      const unitSizeCell = targetRange.getCell(4, 1)
      const heatingCoilCapCell = targetRange.getCell(6, 1)
      const heatingCoilTypeCell = targetRange.getCell(7, 1)
      const fluidTypeCell = targetRange.getCell(8, 1)
      const ewtCell = targetRange.getCell(11, 1)

      // Clear contents before loading
      targetRange.clear()
      targetRange.values = tableData

      // Style calculator background
      const whiteBackground = targetRange.getCell(1, 0).getResizedRange(numRows - 2, numCols - 1)
      applyFormatting(whiteBackground, 'white')

      // Style title
      const titleRange = targetRange.getCell(0, 0).getResizedRange(0, numCols - 1)
      titleRange.merge()
      applyTitleFormatting(titleRange, antecPrimary, 'white')
      applyFormatting(titleRange, 'black', 'Thin')

      // Style input & output cells
      applyOuterBorder(targetRange, 'black', 'Thin')
      applyFormatting(solutionMethodCell, 'black', 'Medium')
      solutionMethodCell.format.fill.color = antecSecondary
      solutionMethodCell.format.horizontalAlignment = 'center'
      solutionMethodCell.format.verticalAlignment = 'center'
      const topInputRange = unitSizeCell.getResizedRange(8, 0)
      applyFormatting(topInputRange, 'black', 'Medium')
      topInputRange.format.fill.color = antecSecondary
      topInputRange.format.horizontalAlignment = 'center'
      topInputRange.format.verticalAlignment = 'center'

      const bottomOutputRange = targetRange.getCell(numInputRows - 2, 1).getResizedRange(numOutputRows, 0)
      applyFormatting(bottomOutputRange, 'black', 'Medium')
      bottomOutputRange.format.horizontalAlignment = 'center'
      bottomOutputRange.format.verticalAlignment = 'center'

      // Style column width
      targetRange.format.autofitColumns()
      setFixedColumnWidth(targetRange, numCols, VVHWC_FIXED_COL_WIDTH)

      // Set dropdowns
      const dropdowns = [
        {
          cell: unitSizeCell,
          options: VVHWC_UNIT_SIZES_DROPDOWN
        },
        {
          cell: heatingCoilCapCell,
          options: VVHWC_HEATING_COIL_CAPACITIES_WITH_DESCRIPTIONS
        },
        {
          cell: heatingCoilTypeCell,
          options: VVHWC_HEATING_COIL_TYPES_WITH_DESCRIPTIONS
        },
        {
          cell: solutionMethodCell,
          options: VVHWC_SOLUTION_METHODS.DROPDOWN
        },
        {
          cell: fluidTypeCell,
          options: VVHWC_FLUID_TYPES_WITH_DESCRIPTIONS
        }
      ]

      dropdowns.forEach(({ cell, options }) => {
        setDropdownAndOptionalColour(cell, options)
      })

      // Load formulas
      solutionMethodCell.load('address')

      await context.sync()

      const solutionMethodAddress = solutionMethodCell.address

      const tableName = `VVHWCPerformanceCalc_${Date.now()}`
      outputCell.formulas = [[`=AntecControls.VVHWC.SOLUTIONMETHOD("${tableName}", ${solutionMethodAddress})`]]

      // Assign table name
      const table = worksheet.tables.add(targetRange.getCell(numInputRows - 2, 0).getResizedRange(numOutputRows, 2), true)
      table.name = tableName
      table.showFilterButton = false
      table.style = null

      ewtCell.values = ' '
      solutionMethodCell.values = VVHWC_SOLUTION_METHODS.FLOW
    } catch (error) {
      console.error('Error: ', error)
    }
    event.completed()
  })
}
