import {
  applyFormatting,
  applyOuterBorder,
  setFixedColumnWidth,
  applyTitleFormatting,
  setDropdownAndOptionalColour
} from '@/components/Ribbons/Calculators/commonFormatting'
import { VFX_UNIT_SIZES_DROPDOWN, VFX_FIXED_COL_WIDTH } from '@/lib/constants'
import { antecSecondary, antecPrimary } from '@/main'
import store from '@/store'

export function btnvfxoperatingcalc (event) {
  window.Excel.run(async (context) => {
    try {
      await store.dispatch('units/loadSelectedUnits')
      const flowUnits = store.getters['units/getUnits']('flow')
      const pressureUnits = store.getters['units/getUnits']('pressure')

      // Define the table structure
      const tableData = [
        ['VFX Min Operating Pressure Drop Calculator', '', ''],
        ['', '', ''],
        ['Unit Size', '', ''],
        ['Min Catalog Flow', '', flowUnits],
        ['Max Catalog Flow', '', flowUnits],
        ['Design Airflow', '', flowUnits],
        ['', '', ''],
        ['Min Operating PD', '', pressureUnits],
        ['', '', '']
      ]

      const activeCell = context.workbook.getActiveCell()
      const numRows = tableData.length
      const numCols = tableData[0].length
      const targetRange = activeCell.getResizedRange(numRows - 1, numCols - 1)

      const unitSizeCell = targetRange.getCell(2, 1)
      const minCatalogFlowCell = targetRange.getCell(3, 1)

      const maxCatalogFlowCell = targetRange.getCell(4, 1)
      const designAirflowCell = targetRange.getCell(5, 1)
      const minOpPDCell = targetRange.getCell(7, 1)

      // Clear contents before loading
      targetRange.clear()
      targetRange.values = tableData

      // Style calculator background
      const whiteBackground = targetRange.getCell(1, 0).getResizedRange(numRows - 2, numCols - 1)
      applyFormatting(whiteBackground, 'white')
      applyOuterBorder(targetRange, 'black', 'Thin')

      // Style title
      const titleRange = targetRange.getCell(0, 0).getResizedRange(0, numCols - 1)
      titleRange.merge()
      applyTitleFormatting(titleRange, antecPrimary, 'white')
      applyFormatting(titleRange, 'black', 'Thin')

      // Style input & output cells
      const topInputRange = unitSizeCell.getResizedRange(3, 0)
      applyFormatting(topInputRange, 'black', 'Medium')
      applyFormatting(minOpPDCell.getResizedRange(0, 0), 'black', 'Medium')
      unitSizeCell.format.fill.color = antecSecondary
      designAirflowCell.format.fill.color = antecSecondary
      topInputRange.format.horizontalAlignment = 'center'
      topInputRange.format.verticalAlignment = 'center'
      minOpPDCell.format.horizontalAlignment = 'center'
      minOpPDCell.format.verticalAlignment = 'center'

      // Set dropdowns
      setDropdownAndOptionalColour(unitSizeCell, VFX_UNIT_SIZES_DROPDOWN, antecSecondary)

      // Style column width
      targetRange.format.autofitColumns()
      setFixedColumnWidth(targetRange, numCols, VFX_FIXED_COL_WIDTH)

      // Load formulas
      unitSizeCell.load('address')
      designAirflowCell.load('address')

      await context.sync()

      const unitSizeAddress = unitSizeCell.address
      const designAirflowAddress = designAirflowCell.address

      minCatalogFlowCell.formulas = [[`=AntecControls.VFX.MINCATALOGFLOW(${unitSizeAddress})`]]
      maxCatalogFlowCell.formulas = [[`=AntecControls.VFX.MAXCATALOGFLOW(${unitSizeAddress})`]]
      minOpPDCell.formulas = [[`=AntecControls.VFX.PRESSUREDROP(${unitSizeAddress}, ${designAirflowAddress})`]]
    } catch (error) {
      console.error('Error: ', error)  // TODO how to show error
    }
  })
  event.completed()
}
