import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export default new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      component: () => import('@/layout/DefaultLayout.vue'),
      children: [
        {
          path: '',
          name: 'MainPage',
          component: () => import('@/components/TaskPane/MainPageTabs/MainPage.vue')
        },
        {
          path: '/zone-balancing',
          name: 'ZoneBalancing',
          component: () => import('@/components/TaskPane/Tools/ZoneBalancing.vue')
        },
        {
          path: '/more-products',
          name: 'MoreProducts',
          component: () => import('@/components/TaskPane/Tools/MoreProducts.vue')
        },
        {
          path: '/valves-list',
          name: 'ValvesList',
          component: () => import('@/components/TaskPane/Tools/ValvesList.vue')
        },
        {
          path: '/valve-accessories',
          name: 'ValveAccessories',
          component: () => import('@/components/TaskPane/Tools/ValveAccessories.vue')
        },
        {
          path: '/VfxPopup',
          name: 'VfxPopup',
          component: () => import('@/components/Ribbons/CreateSchedules/VfxPopup.vue')
        },
        {
          path: '/VvPopup',
          name: 'VvPopup',
          component: () => import('@/components/Ribbons/CreateSchedules/VvPopup.vue')
        },
        {
          path: '/VvhwcPopup',
          name: 'VvhwcPopup',
          component: () => import('@/components/Ribbons/CreateSchedules/VvhwcPopup.vue')
        },
        {
          path: '/VvecPopup',
          name: 'VvecPopup',
          component: () => import('@/components/Ribbons/CreateSchedules/VvecPopup.vue')
        },
        {
          path: '/ExcelHelp',
          name: 'ExcelHelp',
          component: () => import('@/components/HelpPage/ExcelHelp.vue')
        },
        {
          path: '/eula',
          name: 'EULA',
          component: () => import('@/components/EULA.vue')
        },
        {
          path: '/privacy-policy',
          name: 'Privacy',
          component: () => import('@/components/PrivacyPolicy.vue')
        }
      ]
    }
  ]
})
