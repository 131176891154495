<template>
  <base-modal
    :columns="COLUMNS_VFX"
    :output-columns="OUTPUT_COLUMNS_VFX"
    :validation-rules="VALIDATION_RULES_VFX"
    :always-included-columns="alwaysIncludedColumns"
    :tab-name="VFX_TAB_NAME"
  />
</template>

<script>
import { getUrl } from '../../common.js'
import store from '@/store'
import BaseModal, { dialogCallback } from '@/components/Ribbons/CreateSchedules/BaseModal.vue'
import { VFX_TAB_NAME, VFX_PRESSURE_DROP_HEADER } from '@/lib/constants'

const url = getUrl()

window.btnvfx = async function (event) {
  try {
    await store.dispatch('units/loadSelectedUnits')
    await Office.context.ui.displayDialogAsync(
      url + '/#/VfxPopup?unitsType=' + store.getters['units/getSelectedUnits'],
      { height: 50, width: 50, displayInFrame: true, promptBeforeOpen: false },
      dialogCallback
    )
  } catch (error) {
    console.error('Failed to display dialog:', error)
  }
  event.completed()
}

export default {
  name: 'VfxPopup',
  components: { BaseModal },
  data () {
    return {
      COLUMNS_VFX: {
        TOGGLE_ALL_COLUMNS: { id: 'Toggle All Columns' },
        model: { id: 'Model' },
        unitSize: { id: 'Unit Size' },
        maxFlow: { id: `MAX Flow (${this.$store.getters['units/getUnits']('flow')})` },
        minFlow: { id: `MIN Flow (${this.$store.getters['units/getUnits']('flow')})` },
        valveTag: { id: 'Valve Tag' },
        room: { id: 'Room' },
        application: { id: 'Application' },
        quantity: { id: 'Quantity' }
      },
      OUTPUT_COLUMNS_VFX: [
        { id: VFX_PRESSURE_DROP_HEADER + `(${store.getters['units/getUnits']('pressure')})`, formula: '=AntecControls.VFX.PRESSUREDROP(B2, C2)' },
        { id: 'MAX Discharge Sound Power Levels', formula: '=AntecControls.VFX.MAXDISCHARGE(B2, C2, PD)' },
        { id: 'MAX Radiated Sound Power Levels', formula: '=AntecControls.VFX.MAXRADIATED(B2, C2, PD)' },
        { id: 'MAX Inlet Sound Power Levels', formula: '=AntecControls.VFX.MAXINLET(B2, C2, PD)' }
      ],
      VALIDATION_RULES_VFX: {
        'Application': 'SAV | Supply Air Valve, GEV | General Exhaust Valve, FEV | Fume Hood Exhaust Valve, RAV | Return Air Valve, OEV | Other Exhaust Valve',
        'Unit Size': '108, 110, 112, 114, 212, 214, 312, 314'
      },
      alwaysIncludedColumns: [
        'Model',
        'Unit Size',
        `MAX Flow (${this.$store.getters['units/getUnits']('flow')})`,
        `MIN Flow (${this.$store.getters['units/getUnits']('flow')})`,
      ],
      VFX_TAB_NAME
    }
  },
  beforeCreate () {
    this.$store.dispatch('units/setSelectedUnits', this.$route.query.unitsType)
  }
}
</script>
