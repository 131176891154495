import { VVEC_MODEL, LAT_SOLUTION_METHOD } from '@/lib/constants'
import store from '@/store'
import { ApiRequestHandler } from '@/apiHelper/apiRequestHandler'

async function fetchVVECApiResult (operation, unitSize, heatingFlow, electricVoltage, enteringAirTemp, leavingAirTemp, electricCapacity, solutionMethod = null) {
  await store.dispatch('units/loadSelectedUnits')
  const data =
    {
      operation,
      input: {
        model: VVEC_MODEL,
        units: store.getters['units/getSelectedUnits'],
        unitSize,
        heatingFlow,
        electricVoltage,
        enteringAirTemp,
        leavingAirTemp,
        electricCapacity: calculateElectricCapacity(solutionMethod, electricVoltage, electricCapacity)
      }
    }

  const apiHandler = new ApiRequestHandler()
  const response = await apiHandler.postData('vvec/excel/electricCoil', data)

  if (response.data[0].error === null) {
    return response.data[0].result
  } else {
    console.error(response.data[0].error)
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, response.data[0].error)
  }
}

function calculateElectricCapacity (solutionMethod, electricVoltage, electricCapacity) {
  if (solutionMethod === LAT_SOLUTION_METHOD) {
    const phase = electricVoltage.split('|')[0].split('-')[1]
    return (parseInt(phase) / 2)
  } else {
    return electricCapacity
  }
}

export function generateVvecMop (...args) {
  return fetchVVECApiResult('MOP', ...args)
}

export function generateVvecLat (...args) {
  return fetchVVECApiResult('LAT', ...args)
}

export function generateVvecMca (...args) {
  return fetchVVECApiResult('MCA', ...args)
}

export function generateVvecCapacity (...args) {
  return fetchVVECApiResult('Capacity', ...args)
}

export function generateVvecCurrent (...args) {
  return fetchVVECApiResult('Current', ...args)
}

export function generateVvecFv (...args) {
  return fetchVVECApiResult('FV', ...args)
}
